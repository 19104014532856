div.mxRubberband {
	position: absolute;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
	border-color: #0000FF;
	background: #0077FF;
}
.mxCellEditor {
	background: url(data:image/gif;base64,R0lGODlhMAAwAIAAAP///wAAACH5BAEAAAAALAAAAAAwADAAAAIxhI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8egpAAA7);
	_background: url('../images/transparent.gif');
	border-color: transparent;
	border-style: solid;
	display: inline-block;
	position: absolute;
	overflow: visible;
	word-wrap: break-word;
	border-width: 0;
	min-width: 1px;
	resize: none;
	padding: 0px;
	margin: 0px;
}
.mxPlainTextEditor * {
	padding: 0px;
	margin: 0px;
}
div.mxWindow {
	-webkit-box-shadow: 3px 3px 12px #C0C0C0;
	-moz-box-shadow: 3px 3px 12px #C0C0C0;
	box-shadow: 3px 3px 12px #C0C0C0;
	_background: url('../images/window.gif');
	border:1px solid #c3c3c3;
	position: absolute;
	overflow: hidden;
	z-index: 1;
}
table.mxWindow {
	border-collapse: collapse;
	table-layout: fixed;
  	/* font-family: 'Helvetica, Arial, sans-serif'; */
	/* font-size: 8pt; */
}
td.mxWindowTitle {
	_background: url('../images/window-title.gif') repeat-x;
	text-overflow: ellipsis;
	white-space: nowrap;
 	text-align: center;
 	font-weight: bold;
 	overflow: hidden;
	height: 13px;
	padding: 2px;
 	padding-top: 4px;
 	padding-bottom: 6px;
 	color: black;
}
td.mxWindowPane {
	vertical-align: top;
	padding: 0px;
}
div.mxWindowPane {
	overflow: hidden;
	position: relative;
}
td.mxWindowPane td {
  	/* font-family: 'Helvetica, Arial, sans-serif'; */
	font-size: 8pt;
}
td.mxWindowPane input, td.mxWindowPane select, td.mxWindowPane textarea, td.mxWindowPane radio {
  	border-color: #8C8C8C;
  	border-style: solid;
  	border-width: 1px;
  	/* font-family: 'Helvetica, Arial, sans-serif'; */
	/* font-size: 8pt; */
 	padding: 1px;
}
td.mxWindowPane button {
  	font-family: Arial;
  	font-size: 8pt;
  	padding: 2px;
	float: left;
}
img.mxToolbarItem {
	margin-right: 6px;
	margin-bottom: 6px;
	border-width: 1px;
}
select.mxToolbarCombo {
	vertical-align: top;
	border-style: inset;
	border-width: 2px;
}
div.mxToolbarComboContainer {
	padding: 2px;
}
img.mxToolbarMode {
	margin: 2px;
	margin-right: 4px;
	margin-bottom: 4px;
	border-width: 0px;
}
img.mxToolbarModeSelected {
	margin: 0px;
	margin-right: 2px;
	margin-bottom: 2px;
	border-width: 2px;
	border-style: inset;
}
div.mxTooltip {
	-webkit-box-shadow: 3px 3px 12px #C0C0C0;
	-moz-box-shadow: 3px 3px 12px #C0C0C0;
	box-shadow: 3px 3px 12px #C0C0C0;
	background: #FFFFFF;
	border-style: solid;
	border-width: 1px;
	border-color: black;
	font-family: 'Helvetica, Arial, sans-serif';
	font-size: 8pt;
	position: absolute;
	cursor: default;
	padding: 4px;
	color: black;
}
div.mxPopupMenu {
	-webkit-box-shadow: 3px 3px 12px #C0C0C0;
	-moz-box-shadow: 3px 3px 12px #C0C0C0;
	box-shadow: 3px 3px 12px #C0C0C0;
	background: url(data:image/gif;base64,R0lGODlhGgAUAIAAAOzs7PDw8CH5BAAAAAAALAAAAAAaABQAAAIijI+py70Ao5y02lud3lzhD4ZUR5aPiKajyZbqq7YyB9dhAQA7);
	_background: url('../images/window.gif');
	position: absolute;
	border-style: solid;
	border-width: 1px;
	border-color: black;
}
table.mxPopupMenu {
	border-collapse: collapse;
	margin-top: 1px;
	margin-bottom: 1px;
}
tr.mxPopupMenuItem {
	color: black;
	cursor: pointer;
}
tr.mxPopupMenuItemHover {
	background-color: #000066;
	color: #FFFFFF;
	cursor: pointer;
}
td.mxPopupMenuItem {
	padding: 2px 30px 2px 10px;
	white-space: nowrap;
	font-family: Arial;
	font-size: 8pt;
}
td.mxPopupMenuIcon {
	background-color: #D0D0D0;
	padding: 2px 4px 2px 4px;
}
.mxDisabled {
	opacity: 0.2 !important;
	cursor:default !important;
}
.flow {
	stroke-dasharray: 8;
	animation: dash 0.5s linear;
	animation-iteration-count: infinite;
  }
  @keyframes dash {
	to {
	  stroke-dashoffset: -16;
	}
  }