// @import '../controls/SPFabricCore.scss';
// @fontColor: #E4E8EA;

.sidebarcontainer {
  padding: 5px;
  min-width: 160px;
  // max-width: 160px;
 // height: 500px;
  // min-height: 280px;
  // background-color: #fff;
  // border-right: 1px solid @fontColor;
  // box-shadow: 0 0 4px 0 rgba(54, 71, 134, 0.1);
  // overflow-y: scroll;

  // .customsvg {
  //   width: 36px;
  //   height: 46px;
  //   display: block;
  //   position: relative;
  //   overflow: hidden;
  //   cursor: move;
  //   left: 2px;
  //   top: 2px;
  // }



  .customsidebarnode {
  //  overflow: hidden;
    width: 64px;
    height: 64px;
    padding: 1px;
    display: flex;
    position: relative;
    // flex-direction: column;
    // align-items: center;
    justify-content: center;
    cursor: move;
    // box-sizing: border-box;
   // font-size: 12px;
    text-align: center;
    // float:left
    margin: 2px;
    cursor: move;
    text-decoration: none;
    font-family: "Segoe UI WestEuropean", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: small;
    // font-size: $ms-font-size-s;
    // font-weight: $ms-font-weight-regular;


    .tooltip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: #FBFBFB;
      border: 1px solid #DEE0EA;
      border-radius: 2px;
    }

    &:focus {
      text-decoration: none;
    }

    // &.suanfa-node {
    //   width: 180px;
    //   height: 24px;
    //   flex-direction: row;
    //   justify-content: start;
    //   padding: 0 6px;
    // }

    .sidebarnodeimage {
      width: 31px;
      height: 31px;
    }

    .sidebarnodelabel {
      padding-top: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 14px;
      line-height: 14px;
      box-sizing: content-box;
      // white-space: nowrap;
      // width: 63px;
    }

    .svgnode {
      width: 36px;
      height: 36px;
      display: block;
      position: relative;
      overflow: hidden;
      cursor: move;

      .textnode {
        display: inline-block;
        font-size: 12px;
        // font-family: Helvetica;
        // color: #666;
        line-height: 1.2;
        vertical-align: top;
        width: 24px;
        white-space: normal;
        overflow-wrap: normal;
        text-align: center;
      }
    }
  }
}
