body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Helvetica, Arial, sans-serif', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar_sidebarcontainer__1hxqw{padding:5px;min-width:160px}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS{width:64px;height:64px;padding:1px;display:flex;position:relative;justify-content:center;cursor:move;text-align:center;margin:2px;cursor:move;text-decoration:none;font-family:"Segoe UI WestEuropean","Segoe UI",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;font-size:small}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS .sidebar_tooltip__2QdXd{display:flex;flex-direction:column;align-items:center;justify-content:center}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS:hover{background-color:#fbfbfb;border:1px solid #dee0ea;border-radius:2px}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS:focus{text-decoration:none}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS .sidebar_sidebarnodeimage__3VJin{width:31px;height:31px}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS .sidebar_sidebarnodelabel__1O0pS{padding-top:6px;text-overflow:ellipsis;overflow:hidden;height:14px;line-height:14px;box-sizing:content-box}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS .sidebar_svgnode__3tp8K{width:36px;height:36px;display:block;position:relative;overflow:hidden;cursor:move}.sidebar_sidebarcontainer__1hxqw .sidebar_customsidebarnode__1pGsS .sidebar_svgnode__3tp8K .sidebar_textnode__2klWq{display:inline-block;font-size:12px;line-height:1.2;vertical-align:top;width:24px;white-space:normal;overflow-wrap:normal;text-align:center}
.pages_semTalkPages__1a5Jq{min-width:150px;border-width:0px;border-style:hidden}
div.mxRubberband {
	position: absolute;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
	border-color: #0000FF;
	background: #0077FF;
}
.mxCellEditor {
	background: url(data:image/gif;base64,R0lGODlhMAAwAIAAAP///wAAACH5BAEAAAAALAAAAAAwADAAAAIxhI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8egpAAA7);
	_background: url(data:image/gif;base64,R0lGODlhMAAwAIAAAP///wAAACH5BAEAAAAALAAAAAAwADAAAAIxhI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8egpAAA7);
	border-color: transparent;
	border-style: solid;
	display: inline-block;
	position: absolute;
	overflow: visible;
	word-wrap: break-word;
	border-width: 0;
	min-width: 1px;
	resize: none;
	padding: 0px;
	margin: 0px;
}
.mxPlainTextEditor * {
	padding: 0px;
	margin: 0px;
}
div.mxWindow {
	box-shadow: 3px 3px 12px #C0C0C0;
	_background: url(data:image/gif;base64,R0lGODlhGgAUAIAAAOzs7PDw8CH5BAAAAAAALAAAAAAaABQAAAIijI+py70Ao5y02lud3lzhD4ZUR5aPiKajyZbqq7YyB9dhAQA7);
	border:1px solid #c3c3c3;
	position: absolute;
	overflow: hidden;
	z-index: 1;
}
table.mxWindow {
	border-collapse: collapse;
	table-layout: fixed;
  	/* font-family: 'Helvetica, Arial, sans-serif'; */
	/* font-size: 8pt; */
}
td.mxWindowTitle {
	_background: url(data:image/gif;base64,R0lGODlhFwAXAMQAANfX18rKyuHh4c7OzsDAwMHBwc/Pz+Li4uTk5NHR0dvb2+jo6O/v79/f3/n5+dnZ2dbW1uPj44yMjNPT0+Dg4N3d3ebm5szMzAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAXABcAAAWQICESxWiW5Ck6bOu+MMvMdG3f86LvfO/rlqBwSCwaj8ikUohoOp/QaDNCrVqvWKpgezhsv+AwmEIum89ocmPNbrvf64p8Tq/b5Yq8fs/v5x+AgYKDhIAAh4iJiouHEI6PkJGSjhOVlpeYmZUJnJ2en6CcBqMDpaanqKgXq6ytrq+rAbKztLW2shK5uru8vbkhADs=) repeat-x;
	text-overflow: ellipsis;
	white-space: nowrap;
 	text-align: center;
 	font-weight: bold;
 	overflow: hidden;
	height: 13px;
	padding: 2px;
 	padding-top: 4px;
 	padding-bottom: 6px;
 	color: black;
}
td.mxWindowPane {
	vertical-align: top;
	padding: 0px;
}
div.mxWindowPane {
	overflow: hidden;
	position: relative;
}
td.mxWindowPane td {
  	/* font-family: 'Helvetica, Arial, sans-serif'; */
	font-size: 8pt;
}
td.mxWindowPane input, td.mxWindowPane select, td.mxWindowPane textarea, td.mxWindowPane radio {
  	border-color: #8C8C8C;
  	border-style: solid;
  	border-width: 1px;
  	/* font-family: 'Helvetica, Arial, sans-serif'; */
	/* font-size: 8pt; */
 	padding: 1px;
}
td.mxWindowPane button {
  	font-family: Arial;
  	font-size: 8pt;
  	padding: 2px;
	float: left;
}
img.mxToolbarItem {
	margin-right: 6px;
	margin-bottom: 6px;
	border-width: 1px;
}
select.mxToolbarCombo {
	vertical-align: top;
	border-style: inset;
	border-width: 2px;
}
div.mxToolbarComboContainer {
	padding: 2px;
}
img.mxToolbarMode {
	margin: 2px;
	margin-right: 4px;
	margin-bottom: 4px;
	border-width: 0px;
}
img.mxToolbarModeSelected {
	margin: 0px;
	margin-right: 2px;
	margin-bottom: 2px;
	border-width: 2px;
	border-style: inset;
}
div.mxTooltip {
	box-shadow: 3px 3px 12px #C0C0C0;
	background: #FFFFFF;
	border-style: solid;
	border-width: 1px;
	border-color: black;
	font-family: 'Helvetica, Arial, sans-serif';
	font-size: 8pt;
	position: absolute;
	cursor: default;
	padding: 4px;
	color: black;
}
div.mxPopupMenu {
	box-shadow: 3px 3px 12px #C0C0C0;
	background: url(data:image/gif;base64,R0lGODlhGgAUAIAAAOzs7PDw8CH5BAAAAAAALAAAAAAaABQAAAIijI+py70Ao5y02lud3lzhD4ZUR5aPiKajyZbqq7YyB9dhAQA7);
	_background: url(data:image/gif;base64,R0lGODlhGgAUAIAAAOzs7PDw8CH5BAAAAAAALAAAAAAaABQAAAIijI+py70Ao5y02lud3lzhD4ZUR5aPiKajyZbqq7YyB9dhAQA7);
	position: absolute;
	border-style: solid;
	border-width: 1px;
	border-color: black;
}
table.mxPopupMenu {
	border-collapse: collapse;
	margin-top: 1px;
	margin-bottom: 1px;
}
tr.mxPopupMenuItem {
	color: black;
	cursor: pointer;
}
tr.mxPopupMenuItemHover {
	background-color: #000066;
	color: #FFFFFF;
	cursor: pointer;
}
td.mxPopupMenuItem {
	padding: 2px 30px 2px 10px;
	white-space: nowrap;
	font-family: Arial;
	font-size: 8pt;
}
td.mxPopupMenuIcon {
	background-color: #D0D0D0;
	padding: 2px 4px 2px 4px;
}
.mxDisabled {
	opacity: 0.2 !important;
	cursor:default !important;
}
.flow {
	stroke-dasharray: 8;
	animation: dash 0.5s linear;
	animation-iteration-count: infinite;
  }
  @keyframes dash {
	to {
	  stroke-dashoffset: -16;
	}
  }
div.mxTooltip {
	filter:progid:DXImageTransform.Microsoft.DropShadow(OffX=4, OffY=4, 
        Color='#A2A2A2', Positive='true');
}
div.mxPopupMenu {
	filter:progid:DXImageTransform.Microsoft.DropShadow(OffX=4, OffY=4, 
        Color='#C0C0C0', Positive='true');
}
div.mxWindow {
	_filter:progid:DXImageTransform.Microsoft.DropShadow(OffX=4, OffY=4, 
        Color='#C0C0C0', Positive='true');
}
td.mxWindowTitle {
	height: 23px;
}
.mxDisabled {
	filter:alpha(opacity=20) !important;
}

a{color:#333;text-decoration:none}a:hover{color:#333}.tseditor_editorcontainer__10Ped{position:relative;padding-left:5px}.tseditor_editorcontainer__10Ped .tseditor_scrollcontainer__HXz0l{margin:0px auto}.tseditor_editorcontainer__10Ped .tseditor_graphinnercontainer__2LKPK{-webkit-font-smoothing:antialiased}.tseditor_editorcontainer__10Ped .tseditor_graphcontent__2975q{align-items:center;overflow:auto}.tseditor_editorcontainer__10Ped .tseditor_outline__3Uusu{overflow:hidden;width:150px;height:150px;margin:0px;padding-left:0px;border-style:solid;border-width:0}.tseditor_flow__1prdC{stroke-dasharray:8;animation:tseditor_dash__2urd5 .5s linear;animation-iteration-count:infinite}@keyframes tseditor_dash__2urd5{to{stroke-dashoffset:-16}}
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
