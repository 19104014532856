// @import '../controls/SPFabricCore.scss';

a {
  color: #333;
  text-decoration: none;

  &:hover {
    color: #333;
  }
}

.editorcontainer {
  // @include ms-Grid;
  position: relative;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  padding-left: 5px;

  .scrollcontainer {
    // height: 1000px;
    // width: 1000px;
    margin: 0px auto;
  }

  .graphinnercontainer {
    //  position: relative;
    // width: 100%;
    // height: 100%;
    // overflow: auto;
    // display: block;
    // background: $ms-color-white;
    // background: white;
    // font-family: "Segoe UI WestEuropean", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    // font-size: $ms-font-size-m;
    // font-weight: $ms-font-weight-regular;

    //  .row {
    // @include ms-Grid-row;
    // }

    // .column {
    // @include ms-Grid-col;
    // }
  }

  .graphcontent {
    // flex: 1;
    // background-image: url('https://img.alicdn.com/tfs/TB1ZP2zj9zqK1RjSZPcXXbTepXa-150-150.png');
    align-items: center;
    // justify-content: left;
    // width: 100%;
    // height: calc(100% - 40px);
    overflow: auto;
    // style="position:relative;background:#eeeeee;border:6px solid gray;overflow:auto;width:400px;height:200px;"
    //   width: 600px;
    //   min-width: 600px;
    //   height: 600px;
    //   min-height: 600px;
    // position: absolute;
    //   background: #eeeeee;
    //   border: 1px solid gray;
    //   overflow: auto;
  }

  .outline {
    // position: relative;
    overflow: hidden;
    // top: 36px;
    // right: 0px;
    width: 150px;
    height: 150px;
    margin: 0px;
    padding-left: 0px;
    // background: transparent;
    border-style: solid;
    border-width: 0;
    // border-color:  #333;;
  }
}

.flow {
  stroke-dasharray: 8;
  animation: dash 0.5s linear;
  animation-iteration-count: infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -16;
  }
}